import Vue from "vue";
import VueApollo from "vue-apollo";
import {
  createApolloClient,
  restartWebsockets
} from "vue-cli-plugin-apollo/graphql-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createLink } from "apollo-absinthe-upload-link";
import auth from "@/plugins/auth.js";

import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
const introspectionQueryResultData = {
  __schema: {
    types: ["Template", "Action", "Audience"].map(assetName => ({
      possibleTypes: ["Email", "Sms", "Facebook"].map(channel => ({
        name: `${channel}${assetName}`
      })),
      name: assetName,
      kind: "INTERFACE"
    }))
  }
};
import { InMemoryCache } from "apollo-cache-inmemory";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint = `${process.env.VUE_APP_API_URL}/graphql`;

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`, {
        locations: locations,
        path: path
      });
    });
  // eslint-disable-next-line no-console
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const absintheUploadLink = createLink({ uri: httpEndpoint });

// Fragments handling (fragmentTypes need to be updated with the schema
// cf. https://www.apollographql.com/docs/react/advanced/fragments.html)
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});
const fragmentHandlingCache = new InMemoryCache({ fragmentMatcher });

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,

  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: ApolloLink.from([onErrorLink, absintheUploadLink]),
  // Fix a warning in console about "calling concat on a terminating link"
  defaultHttpLink: false,

  // Override default cache
  cache: fragmentHandlingCache,

  // Override the way the Authorization header is set
  getAuth: () => `Bearer ${auth.token}`

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Allow to make gql call in regular js files
// Create apollo client
export const { apolloClient, wsClient } = createApolloClient(defaultOptions);
apolloClient.wsClient = wsClient;

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
        errorPolicy: "all"
      }
    },
    errorHandler({ message }) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        message
      );
    }
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
