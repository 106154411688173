import * as types from "@/store/mutation-types";
import enigma from "enigma.js";
import schema from "enigma.js/schemas/12.20.0.json";
import auth from "@/plugins/auth.js";

const bookmarkListQuery = {
  qInfo: { qType: "BookmarkList", qId: "MUAjZfPLY" },
  qBookmarkListDef: {
    qType: "bookmark",
    qMeta: {
      title: "/qMetaDef/title",
      description: "/qMetaDef/description",
      modifiedDate: "/qMetaDef/modifiedDate"
    }
  }
};

const emailListQuery = bookmarks => {
  const bookmarkQuery = bookmarks.map(id => `P({'${id}'})`).join(" + ");
  return {
    qInfo: { qId: "ListObject01", qType: "ListObject" },
    qListObjectDef: {
      qDef: {
        qFieldDefs: [
          `=aggr(ONLY({1<[Email du spectateur]=${bookmarkQuery}>} [Email du spectateur]),[Email du spectateur])`
        ],
        qFieldLabels: ["Email du spectateur"],
        qSortCriterias: [
          {
            qSortByAscii: 1,
            qSortByLoadOrder: 1,
            qSortByNumeric: 1,
            qSortByState: 1
          }
        ]
      },
      qInitialDataFetch: [{ qHeight: 0, qLeft: 0, qTop: 0, qWidth: 1 }],
      qLibraryId: "",
      qShowAlternatives: false,
      qStateName: "$"
    }
  };
};

const state = {
  bookmarks: [],
  bookmarksRequestLoading: false,
  totalAudience: 0,
  isTotalContactsLoading: false,
  appList: []
};

const getters = {};

const actions = {
  fetchBookmarks({ dispatch, commit }) {
    commit(types.SET_BOOKMARKS_REQUEST_LOADING, true);

    dispatch("getQlikSession").then(({ session, doc }) => {
      doc
        .createSessionObject(bookmarkListQuery)
        .then(object => {
          session.on("traffic:received", data => {
            if (data.change && data.change.includes(object.handle)) {
              dispatch("fetchBookmarks");
            }
          });
          return object.getLayout();
        })
        .then(layout => {
          const bookmarks = layout.qBookmarkList.qItems.map(item => {
            return {
              name: item.qMeta.title,
              id: item.qInfo.qId,
              qlikId: item.qInfo.qId,
              modifiedDate: item.qMeta.modifiedDate,
              description: item.qMeta.description
            };
          });
          commit(types.SET_BOOKMARKS, bookmarks);

          commit(types.SET_BOOKMARKS_REQUEST_LOADING, false);
        });
    });
    commit(types.SET_BOOKMARKS_REQUEST_LOADING, false);
  },
  getAudienceInfo({ commit, dispatch }, bookmarks) {
    // Set total audience to "0" when no bookmarks are selected
    // workaround the default Qlik behavior which is to return the number of people in the whole DB
    if (!bookmarks.length) return commit(types.SET_TOTAL_AUDIENCE, 0);

    commit(types.SET_IS_TOTAL_CONTACTS_LOADING, true);

    dispatch("getQlikSession").then(({ _session, doc }) => {
      doc
        .createSessionObject(emailListQuery(bookmarks))
        .then(emailList => emailList.getLayout())
        .then(emailListLayout => {
          const totalAudience =
            emailListLayout.qListObject.qDimensionInfo.qCardinal;
          commit(types.SET_TOTAL_AUDIENCE, totalAudience);
          commit(types.SET_IS_TOTAL_CONTACTS_LOADING, false);
        });
    });
  },
  async exportAudienceData({ dispatch }, bookmarks) {
    const { VUE_APP_QLIK_HTTPS_LOGIN_URL } = process.env;
    const { doc } = await dispatch("getQlikSession").catch(
      err => throw new Error("Fail to get Qlik session: ", err)
    );
    const emailList = await doc
      .createSessionObject(emailListQuery(bookmarks))
      .catch(err => throw new Error("Fail to get email list: ", err));
    const [exportEmailList, qlikTicket] = await Promise.all([
      emailList.exportData("CSV_C", "/qListObjectDef", "", "A"),
      dispatch("getQlikTicket")
    ]).catch(err => throw new Error("Fail to export email list: ", err));
    const {
      data: { ticket }
    } = await qlikTicket
      .json()
      .catch(err => throw new Error("Fail to get Qlik ticket: ", err));

    return `${VUE_APP_QLIK_HTTPS_LOGIN_URL}${exportEmailList.qUrl}&qlikTicket=${ticket}`;
  },
  getQlikTicket() {
    const url = process.env.VUE_APP_IGNITION_API_URL + "/api/qlik/ticket";

    return fetch(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${auth.token}`
      })
    }).catch(err => throw new Error("Fail to fetch Qlik ticket: ", err));
  },
  async getQlikSession({ dispatch }) {
    if (!window.qlikSession) {
      const { session, doc } = await dispatch("createQlikSession").catch(
        err => throw new Error("Fail to get Qlik session: ", err)
      );
      Object.assign(window, { qlikSession: session, qlikDoc: doc });
    }

    return { session: window.qlikSession, doc: window.qlikDoc };
  },
  fetchAppList({ dispatch, commit }) {
    return new Promise((resolve, _reject) => {
      dispatch("getQlikTicket").then(res =>
        res.json().then(json => {
          const ticket = json.data.ticket;

          var session = enigma.create({
            schema,
            url: `${process.env.VUE_APP_QLIK_WSS_LOGIN_URL}/?qlikTicket=${ticket}`,
            createSocket: url => new WebSocket(url)
          });

          session
            .open()
            .then(global => global.getDocList())
            .then(docList => {
              commit(types.SET_APP_LIST, docList);

              resolve(docList);
            });
        })
      );
    });
  },
  createQlikSession({ dispatch, getters }) {
    return new Promise((resolve, _reject) => {
      dispatch("getQlikTicket").then(res =>
        res.json().then(json => {
          const ticket = json.data.ticket;

          var session = enigma.create({
            schema,
            url: `${process.env.VUE_APP_QLIK_WSS_LOGIN_URL}/${getters.qlikAppId}?qlikTicket=${ticket}`,
            createSocket: url => new WebSocket(url)
          });

          session.on("closed", () => (window.qlikSession = null));

          // Use this to inspect and debug Qlik trafic:

          // eslint-disable-next-line no-console
          // qlikSession.on("traffic:sent", data => console.log("sent:", data));
          // qlikSession.on("traffic:received", data => {
          // eslint-disable-next-line no-console
          //   console.log("received:", data);
          // });

          session
            .open()
            .then(global => global.openDoc(getters.qlikAppId))
            .then(doc => {
              resolve({ session, doc });
            });
        })
      );
    });
  }
};

const mutations = {
  [types.SET_BOOKMARKS](state, bookmarks) {
    state.bookmarks = bookmarks;
  },
  [types.SET_BOOKMARKS_REQUEST_LOADING](state, isLoading) {
    state.bookmarksRequestLoading = isLoading;
  },
  [types.SET_TOTAL_AUDIENCE](state, total) {
    state.totalAudience = total;
  },
  [types.SET_IS_TOTAL_CONTACTS_LOADING](state, isLoading) {
    state.isTotalContactsLoading = isLoading;
  },
  [types.SET_APP_LIST](state, appList) {
    state.appList = appList;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
