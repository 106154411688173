import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "facebookAction",
      path: "/:actionId/:tab?",
      props: true,
      component: () => import("@/FacebookAction/FacebookAction.vue")
    }
  ]
});

export default router;
