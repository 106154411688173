import Vue from "vue";
import Buefy from "buefy";

// import FA icons used by buefy
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faAngleDown);

Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultDayNames: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
  defaultMonthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
  ],
  defaultFirstDayOfWeek: 1
});
