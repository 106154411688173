<template>
  <div id="app" class="h-screen overflow-auto p-1">
    <RouterView />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import init from "@/init.js";

export default {
  name: "app",
  mounted() {
    init(this);
    this.selectQlikApp(this.$qlikApp);
  },
  methods: mapActions(["selectQlikApp"])
};
</script>
