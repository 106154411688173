// Auto generated by fontawesome-autoimport

// fab
import {
  faFacebook as fabFacebook,
  faInstagram as fabInstagram
} from "@fortawesome/free-brands-svg-icons";

// fal
import {
  faAngleDown as falAngleDown,
  faAngleRight as falAngleRight,
  faBookmark as falBookmark,
  faFileCsv as falFileCsv,
  faInfoCircle as falInfoCircle
} from "@fortawesome/pro-light-svg-icons";

// far
import { faEnvelope as farEnvelope } from "@fortawesome/pro-regular-svg-icons";

// fas
import {
  faBookmark as fasBookmark,
  faCaretDown as fasCaretDown,
  faCaretUp as fasCaretUp,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faClock as fasClock,
  faEnvelope as fasEnvelope,
  faExclamationCircle as fasExclamationCircle,
  faInfoCircle as fasInfoCircle,
  faLongArrowLeft as fasLongArrowLeft,
  faQuestionCircle as fasQuestionCircle,
  faSpinner as fasSpinner,
  faTag as fasTag,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
  faUpload as fasUpload
} from "@fortawesome/pro-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
library.add(
  fabFacebook,
  fabInstagram,
  falAngleDown,
  falAngleRight,
  falBookmark,
  falFileCsv,
  falInfoCircle,
  farEnvelope,
  fasBookmark,
  fasCaretDown,
  fasCaretUp,
  fasCheck,
  fasCheckCircle,
  fasClock,
  fasEnvelope,
  fasExclamationCircle,
  fasInfoCircle,
  fasLongArrowLeft,
  fasQuestionCircle,
  fasSpinner,
  fasTag,
  fasTimes,
  fasTimesCircle,
  fasUpload
);
