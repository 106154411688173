import * as types from "@/store/mutation-types";

const defaultState = () => ({ qlikApp: { id: null, name: null } });

const state = defaultState();

const getters = {
  qlikAppId: state => state.qlikApp.id
};

const actions = {
  selectQlikApp({ commit, dispatch }, qlikApp) {
    commit(types.SELECT_QLIK_APP, qlikApp);
    dispatch("fetchBookmarks");
  },
  resetConfig({ commit }) {
    commit(types.RESET_CONFIG);
  }
};

const mutations = {
  [types.SELECT_QLIK_APP](state, qlikApp) {
    window.qlikSession = null;
    window.qlikDoc = null;
    state.qlikApp = qlikApp;
  },
  [types.RESET_CONFIG](state) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
