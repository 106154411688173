import Vue from "vue";
import VueGtag, { bootstrap } from "vue-gtag";
import router from "@/router.js";

const { VUE_APP_GOOGLE_ANALYTICS_ID, NODE_ENV } = process.env;

export const setGTagUser = ({ $user, $gtag }) =>
  /@delight-data\.com$/.test($user.email) ||
  bootstrap().then(() => $gtag.config({ user_id: $user.sub }));

Vue.use(
  VueGtag,
  {
    config: { id: VUE_APP_GOOGLE_ANALYTICS_ID },
    appName: "Activation",
    enabled: NODE_ENV === "production",
    bootstrap: false,
    pageTrackerScreenviewEnabled: true
  },
  router
);
