import Vue from "vue";

// get context from URL query parameters
const { searchParams } = new URL(window.location.href);
const { token, userSub, userName, userEmail, qlikAppId, qlikAppName } = [
  ...searchParams.entries()
].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const auth = { token };
export const user = { sub: userSub, name: userName, email: userEmail };
export const qlikApp = { id: qlikAppId, name: qlikAppName };

Vue.use({
  install: Vue =>
    Object.assign(Vue.prototype, {
      $auth: auth,
      $user: user,
      $qlikApp: qlikApp
    })
});

export default auth;
